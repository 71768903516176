<template>
  <base-section
    id="k-d-a-careers-second"
    class="white"
    :space="g_bLowestBr ? 0 : 100"
    top-inner-shadow
  >
    <v-container class="pa-0 px-7 py-7 py-sm-0">
      <v-row align="center" no-gutters>
        <v-col
          v-for="(img, index) in m_arrImgSrcs"
          :key="`image-careers-second-${index}`"
          cols="3"
          style="padding: 0 10px"
        >
          <base-img :max-height="g_bLowestBr ? 72 : 160" contain :src="img" />
        </v-col>

        <v-col cols="12">
          <h5
            :class="`kda-ts-${
              g_bLowerBr ? '16' : '36'
            }pt mt-12 wt-extrabold font-italic text-center`"
          >
            {{ m_strDescription }}
          </h5>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'KDACareersSecond',

  mixins: [BaseBreakpoint],

  computed: {
    m_strDescription() {
      return this.$vuetify.lang.t('$vuetify.kda.careers.second');
    },
    m_arrImgSrcs() {
      const result = [];

      for (let i = 0; i < 4; i++) {
        result.push(require(`@/assets/photos/careers-second-${i + 1}.png`));
      }

      return result;
    }
  }
};
</script>
